<template>
  <div>
    <RequestsTable
      :requests-loading="loading"
      :requests="requests"
      :page-limit="PAGE_LIMIT"
      :current-page="currentPage"
      :businesses="businesses"
      with-visibility-eye
      @update-request="updateRequest"
      @show-response-request-modal="showResponseRequestModal"
    />
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(totalCount / PAGE_LIMIT)"
        :total="totalCount"
      />
    </div>
    <RequestResponseModal
      v-if="requestResponseModalRequest"
      :request="requestResponseModalRequest"
      @close="closeRequestResponseModal"
      @snooze="showRequestSnoozeModal = true"
      @create-request-response="handleCreateRequestResponse"
      @update-request="handleSnoozeRequest"
    />
    <RequestSnoozeModal
      v-if="showRequestSnoozeModal"
      :request="requestResponseModalRequest"
      @update-request="handleSnoozeRequest"
      @close="showRequestSnoozeModal = false"
    />
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { RequestsTable, RequestResponseModal, RequestSnoozeModal } from '../components';
import { useRequests, useCreateResponse } from '../compositions/useRequests';

const PAGE_LIMIT = 25;

export default {
  name: 'OpenRequests',
  components: {
    RequestsTable,
    RequestResponseModal,
    RequestSnoozeModal,
  },
  props: {
    businesses: { type: Array, default: () => [] },
    requestToShow: { type: Object, default: null },
  },
  setup(props) {
    const requestToResponseIndex = ref(null);
    const preLoadedRequest = ref(props.requestToShow || null);
    const root = getCurrentInstance().proxy;
    const currentPage = ref(1);
    const showRequestSnoozeModal = ref(false);

    const targetBusinessIdFilter = computed({
      get: () => root.$route.query.targetBusinessId,
    });
    const sourceBusinessIdFilter = computed({
      get: () => root.$route.query.sourceBusinessId,
    });

    const sortBy = computed({
      get: () => root.$route.query.sortBy,
    });

    const orderBy = computed({
      get: () => root.$route.query.orderBy,
    });

    const variables = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
      targetBusinessId: targetBusinessIdFilter.value,
      sourceBusinessId: sourceBusinessIdFilter.value,
      closed: false,
      isAwaitingResponse: true,
      sort:
        sortBy.value && orderBy.value
          ? {
              sortBy: sortBy.value,
              orderBy: orderBy.value === -1 ? 'asc' : 'desc',
            }
          : {
              sortBy: 'activeAt',
              orderBy: 'asc',
            },
    }));

    watch([targetBusinessIdFilter, sourceBusinessIdFilter], () => (currentPage.value = 1), { immediate: true });

    const {
      requests,
      totalCount,
      loading: requestLoading,
      updateRequest,
      refetch: refetchRequests,
    } = useRequests(variables);
    const { mutate: createResponse, onDone: onResponseCreated, onError: onResponseCreateError } = useCreateResponse();

    onResponseCreated(() => {
      refetchRequests();
      root.$message.success(root.$t('requests.serviceProviderResponseModal.submittedForReview'));
    });
    onResponseCreateError(() => {
      root.$message.error(root.$t('commons.messages.action.error'));
    });

    const showResponseRequestModal = (requestIndex) => (requestToResponseIndex.value = requestIndex);

    const requestResponseModalRequest = computed(
      () => preLoadedRequest.value ?? requests.value[requestToResponseIndex.value]
    );

    const closeRequestResponseModal = () => {
      preLoadedRequest.value = null;
      requestToResponseIndex.value = null;
    };

    return {
      PAGE_LIMIT,
      requests,
      totalCount,
      loading: requestLoading,
      currentPage,
      updateRequest,
      createResponse,
      showResponseRequestModal,
      requestToResponseIndex,
      preLoadedRequest,
      showRequestSnoozeModal,
      requestResponseModalRequest,
      closeRequestResponseModal,
    };
  },
  methods: {
    async handleCreateRequestResponse(responseText) {
      const respondToRequest = this.preLoadedRequest ?? this.requests[this.requestToResponseIndex];

      this.closeRequestResponseModal();

      await this.createResponse({
        requestId: respondToRequest.id,
        createParams: { text: responseText },
      });
    },
    async handleSnoozeRequest(updateData) {
      await this.updateRequest(updateData);

      this.showRequestSnoozeModal = false;
      this.closeRequestResponseModal();

      this.$message.success(this.$i18n.t('requests.snoozeModal.requestSnoozedSuccessfully'));
    },
  },
};
</script>
