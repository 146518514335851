<template>
  <el-popover :key="popoverId" v-model="showContactCard" trigger="manual" placement="bottom" popper-class="p-0">
    <ContactCard
      :user="{ type: 'tenant', payload: { worksAt: business.id } }"
      :customer="business"
      :supplier="business"
      :token="token"
      :business-id="tenantId"
    />
    <template #reference>
      <TruncatedText>
        <Button type="link" class="text-typography-primary p-0" @click.stop="togglePopover">
          <span>{{ business.name }}</span>
        </Button>
      </TruncatedText>
    </template>
  </el-popover>
</template>

<script>
import { ref, watch } from 'vue';

import { ContactCard } from '@/modules/contact';
import { Button, TruncatedText } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

export default {
  name: 'BusinessNameWithPopover',
  components: {
    ContactCard,
    Button,
    TruncatedText,
  },
  props: {
    business: { type: Object, required: true },
    tenantId: { type: String, required: true },
    popoverId: { type: String, default: null },
    currentBusinessPopover: { type: String, default: null },
  },
  setup(props, { emit }) {
    const { token } = useTenancy();
    const showContactCard = ref(false);

    watch(
      () => props.currentBusinessPopover,
      (newVal) => {
        showContactCard.value = newVal === props.popoverId;
      }
    );

    const togglePopover = () => emit('set-business-popover', props.popoverId);
    return { token, showContactCard, togglePopover };
  },
};
</script>
