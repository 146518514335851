<template>
  <div>
    <Table
      v-loading="requestsLoading"
      custom-class="requests-table"
      :data="tableData"
      :columns="columns"
      :active-sort="activeSort"
      show-index
      rounded
      border
      @row-click="$emit('show-response-request-modal', $event)"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #[`cell-${requestStatusTime}`]="{ rowData: { request, comment } }">
        <div class="d-flex gap-2">
          <RequestTag :request="request" @update-request="handleRequestUpdate" />
          <div class="position-relative">
            <div
              :id="request.id"
              class="truncated-text"
              :class="{ expandableComment: isExpandable(request.id, comment) }"
            >
              <div v-for="(line, index) in comment" :key="index">
                {{ line }}
                <br />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template
        #cell-details="{
          rowData: { request, details, templated, templatedDescription, rejectReason, visibleToSupplier },
          rowIndex,
        }"
      >
        <div class="d-flex gap-2 align-items-center">
          <el-popover
            :key="request.id"
            placement="top"
            effect="light"
            trigger="hover"
            popper-class="p-0 text-typography-primary"
          >
            <div class="request-details-tooltip p-4">
              <div v-if="rejectReason" class="d-flex flex-column gap-2">
                <div class="d-flex justify-content-between gap-2">
                  <div class="d-flex gap-2">
                    <NoticeFullIcon fill="#E52044" :size="16" class="flex-shrink-0" :style="{ 'margin-top': '1px' }" />
                    <p class="fw-bold text-break">{{ $t('requests.invalidResponseFeedback') }}</p>
                  </div>
                  <el-tooltip :content="$t('requests.copyRequest')" placement="top">
                    <Button type="icon" class="mt-n1" @click.stop="copyToClipboard(rejectReason)">
                      <CopyIcon :size="16" />
                    </Button>
                  </el-tooltip>
                </div>
                <div :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'" class="d-flex flex-column gap-1">
                  <div v-for="(line, rejectReasonLineIndex) in rejectReason.split('\n')" :key="rejectReasonLineIndex">
                    {{ line }}
                  </div>
                  <Button
                    type="link"
                    class="text-typography-primary text-decoration-underline p-0 text-start"
                    @click="$emit('show-response-request-modal', rowIndex)"
                  >
                    <p>{{ $t('commons.continueReading') }}</p>
                  </Button>
                </div>
              </div>
              <div v-else class="d-flex flex-column gap-3">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center gap-2">
                    <RequestIcon :width="16" :height="16" />
                    <p class="fw-bold">{{ $t('requests.requestDetails') }}</p>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <el-tooltip :content="$t('requests.copyRequest')" placement="top">
                      <Button type="icon" class="mx-n1" @click.stop="copyToClipboard(details)">
                        <CopyIcon :size="16" />
                      </Button>
                    </el-tooltip>
                    <span class="text-typography-secondary">&#x2022;</span>
                    <div
                      v-if="request.createdBy"
                      class="d-flex rounded-pill justify-content-center align-items-center rounded-div-container"
                    >
                      <div v-if="request.createdBy.profilePictureUrl">
                        <img :src="request.createdBy.profilePictureUrl" :width="16" :height="16" />
                      </div>
                      <div
                        v-else
                        class="bg-primary rounded-pill d-flex justify-content-center align-items-center initials-pill"
                      >
                        <small class="text-uppercase text-white fs-bold initials-text">{{
                          request.createdBy.firstName?.[0] + request.createdBy.lastName?.[0]
                        }}</small>
                      </div>
                    </div>
                    <span class="text-typography-secondary">&#x2022;</span>
                    <small class="text-typography-secondary">{{ formatMsDate(request.createdAt) }}</small>
                  </div>
                </div>
                <div>
                  <div
                    v-for="(line, detailsLineIndex) in details.split('\n')"
                    :key="detailsLineIndex"
                    :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
                  >
                    {{ line }}
                  </div>
                </div>
              </div>
            </div>
            <template slot="reference">
              <RequestInvalidIcon v-if="rejectReason" :width="16" :height="16" class="flex-shrink-0" />
              <RequestIcon v-else :width="16" :height="16" class="text-typography-secondary flex-shrink-0" />
            </template>
          </el-popover>
          <div v-if="templated">
            <el-tooltip
              v-if="withVisibilityEye && visibleToSupplier"
              :content="$t('requestsSupplier.table.tags.tooltip.customerVisible')"
              placement="top"
              effect="dark"
            >
              <ShowIcon />
            </el-tooltip>
          </div>
          <span class="truncated-text" :class="{ 'fw-bold': templated }">{{ templatedDescription || details }}</span>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADERS.RELATED_TASK}`]="{ rowData: { task } }">
        <Button type="link" class="fw-normal p-0 text-typography-primary" @click.stop="openTask(task)">
          {{ $t(`requests.table.taskNames.${task.type}`) }}
        </Button>
      </template>
      <template #[`cell-${TABLE_HEADERS.SOURCE}`]="{ rowData: { sourceBusiness, tenantId, request } }">
        <BusinessNameWithPopover
          :business="sourceBusiness"
          :tenant-id="tenantId"
          :popover-id="`${request.id}-${TABLE_HEADERS.SOURCE}`"
          :current-business-popover="currentOpenBusinessPopover"
          @set-business-popover="handleBusinessPopover"
        />
      </template>
      <template #[`cell-${TABLE_HEADERS.TARGET}`]="{ rowData: { targetBusiness, tenantId, request } }">
        <BusinessNameWithPopover
          :business="targetBusiness"
          :tenant-id="tenantId"
          :popover-id="`${request.id}-${TABLE_HEADERS.TARGET}`"
          :current-business-popover="currentOpenBusinessPopover"
          @set-business-popover="handleBusinessPopover"
        />
      </template>
      <template #filter-target>
        <div>
          <el-select
            v-model="targetBusinessIdFilter"
            filterable
            clearable
            :popper-append-to-body="false"
            :placeholder="$t('commons.searchWithDots')"
          >
            <el-option v-for="business in businesses" :key="business.id" :label="business.name" :value="business.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template #filter-source>
        <el-select
          v-model="sourceBusinessIdFilter"
          filterable
          clearable
          :popper-append-to-body="false"
          :placeholder="$t('commons.searchWithDots')"
        >
          <el-option v-for="business in businesses" :key="business.id" :label="business.name" :value="business.id">
          </el-option>
        </el-select>
      </template>
    </Table>
    <div class="stylelessDiv"></div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { allPass, hasIn } from 'ramda';

import { Table, Button } from '@/modules/core';
import { ShowIcon, RequestIcon, RequestInvalidIcon, CopyIcon, NoticeFullIcon } from '@/assets/icons';

import RequestTag from './RequestTag';
import BusinessNameWithPopover from './BusinessNameWithPopover';
import { useBusinessRelation } from '../compositions/useBusinessRelation';

const TASK_TYPE = {
  SUPPLIER_CREATION: 'supplierCreation',
  HANDLE_RECONCILIATION: 'handleReconciliation',
  BALANCE_ALIGNMENT: 'balanceAlignment',
  UNCERTAIN_BILLING: 'uncertainBilling',
  DECLARE: 'declare',
  UPLOAD: 'upload',
  RECORD: 'record',
  ALLOCATION_NUMBER: 'allocation',
};

const TABLE_HEADERS = {
  REQUEST_STATUS: 'requestStatus',
  OPENED_BEFORE: 'openedBefore',
  CLOSED_BEFORE: 'closedBefore',
  RESPONSE_CREATED_AT: 'responseCreatedAt',
  REQUEST: 'request',
  TARGET: 'target',
  SOURCE: 'source',
  RELATED_TASK: 'relatedTask',
};

const TEMPLATES_VISIBLE_TO_SUPPLIER = [
  'balanceAlignment',
  'balanceAlignmentReconciliationStatement',
  'balanceAlignmentMissingDocuments',
];

export default {
  components: {
    Table,
    RequestTag,
    Button,
    BusinessNameWithPopover,
    ShowIcon,
    RequestIcon,
    RequestInvalidIcon,
    CopyIcon,
    NoticeFullIcon,
  },
  props: {
    withVisibilityEye: { type: Boolean, default: false },
    requestsLoading: { type: Boolean, default: false },
    requests: { type: Array, required: true },
    currentPage: { type: Number, required: true },
    pageLimit: { type: Number, required: true },
    businesses: { type: Array, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const targetBusinessIdFilter = computed({
      get: () => root.$route.query.targetBusinessId,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, targetBusinessId: value || undefined } }),
    });
    const sourceBusinessIdFilter = computed({
      get: () => root.$route.query.sourceBusinessId,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, sourceBusinessId: value || undefined } }),
    });
    const isClosedRequests = computed(() => props.requests[0]?.closed);
    const isInReviewRequests = computed(() => !props.requests[0]?.isAwaitingResponse && !props.requests[0]?.closed);

    const { businessesClarityWorksWith } = useBusinessRelation();

    return {
      TABLE_HEADERS,
      sourceBusinessIdFilter,
      targetBusinessIdFilter,
      activeSort: ref({
        direction: -1,
        columnKey: computed(() =>
          isClosedRequests.value
            ? TABLE_HEADERS.CLOSED_BEFORE
            : isInReviewRequests.value
            ? TABLE_HEADERS.RESPONSE_CREATED_AT
            : TABLE_HEADERS.OPENED_BEFORE
        ),
      }),
      businessesClarityWorksWith,
      root,
      isClosedRequests,
      isInReviewRequests,
      currentOpenBusinessPopover: ref(null),
    };
  },
  computed: {
    requestStatusTime() {
      return this.isClosedRequests
        ? TABLE_HEADERS.CLOSED_BEFORE
        : this.isInReviewRequests
        ? TABLE_HEADERS.RESPONSE_CREATED_AT
        : TABLE_HEADERS.OPENED_BEFORE;
    },
    columns() {
      const cols = [
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.REQUEST_STATUS}`),
          key: this.requestStatusTime,
          minWidth: '19rem',
          sortCallback: (direction) => {
            this.activeSort = {
              direction,
              columnKey: this.requestStatusTime,
            };
            this.root.$router.replace({
              ...this.root.$route,
              query: {
                ...this.root.$route.query,
                orderBy: direction,
                sortBy: this.isClosedRequests ? 'closedAt' : this.isInReviewRequests ? 'responseCreatedAt' : 'activeAt',
              },
            });
          },
        },

        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.REQUEST}`),
          key: 'details',
          width: '31rem',
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.TARGET}`),
          key: TABLE_HEADERS.TARGET,
          width: '10rem',
          filterActive: !!this.targetBusinessIdFilter,
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.SOURCE}`),
          key: TABLE_HEADERS.SOURCE,
          width: '10rem',
          filterActive: !!this.sourceBusinessIdFilter,
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.RELATED_TASK}`),
          key: TABLE_HEADERS.RELATED_TASK,
          width: '12rem',
        },
      ];
      return cols;
    },
    tableData() {
      return this.requests.map((request) => {
        const {
          closedAt,
          details,
          sourceBusiness,
          targetBusiness,
          targetBusinessId,
          task,
          comment,
          responses,
          template,
          activeAt,
          isAwaitingResponse,
        } = request;

        const lastResponse = (responses || []).at(-1);

        const visibleToSupplier =
          TEMPLATES_VISIBLE_TO_SUPPLIER.includes(template) &&
          this.businessesClarityWorksWith.includes(targetBusinessId);

        return {
          details,
          templatedDescription: this.getDescriptionByTemplate(request),
          sourceBusiness,
          targetBusiness,
          task,
          comment: comment ? comment.split('\n') : [],
          request,
          tenantId: task.businessId,
          rejectReason: lastResponse?.reject?.text,
          templated: template && template !== 'other',
          visibleToSupplier,
          closedBefore: closedAt,
          responseCreatedAt: !isAwaitingResponse && !closedAt && lastResponse?.createdAt,
          openedBefore: activeAt,
        };
      });
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    getDescriptionByTemplate({ template, templateData }) {
      if (templateData && template && template !== 'other') {
        const templateDescription = {
          title: `${this.$t(`requests.requestsButton.popover.templates.${template}.title`)} `,
        };

        switch (template) {
          case 'balanceAlignment':
          case 'balanceAlignmentCheckNewInvoices':
          case 'balanceAlignmentReconciliationStatement': {
            templateDescription.date = `${this.$t(`requests.ofDate`, {
              date: DateTime.fromISO(templateData[template]?.reconciliationPeriodEnd).toFormat('dd.MM.yyyy'),
            })}`;
            break;
          }

          case 'balanceAlignmentMissingDocuments': {
            templateDescription.date = `${this.$t(`requests.ofMonth`, {
              date: DateTime.fromISO(templateData[template]?.documents?.[0].docDate).toFormat('MMMM yy'),
            })}`;
            break;
          }

          default:
            break;
        }
        const validDescription = allPass([hasIn('date'), hasIn('title')])(templateDescription);
        return validDescription && `${templateDescription.title} ${templateDescription.date}`;
      }
    },
    handleRequestUpdate(updateData) {
      this.$emit('update-request', updateData);
    },
    getTaskRouteName(taskType) {
      switch (taskType) {
        case TASK_TYPE.SUPPLIER_CREATION:
          return 'supplierTasks';
        case TASK_TYPE.HANDLE_RECONCILIATION:
          return 'reconciliationTask';
        case TASK_TYPE.UNCERTAIN_BILLING:
          return 'uncertainBillingTask';
        case TASK_TYPE.UPLOAD:
          return 'classifyDocuments';
        case TASK_TYPE.RECORD:
          return 'recordDocuments';
        default:
          return '';
      }
    },
    openTask(task) {
      if (
        [
          TASK_TYPE.DECLARE,
          TASK_TYPE.BALANCE_ALIGNMENT,
          TASK_TYPE.HANDLE_RECONCILIATION,
          TASK_TYPE.UNCERTAIN_BILLING,
          TASK_TYPE.ALLOCATION_NUMBER,
        ].includes(task.type)
      ) {
        const taskUrl = this.$router.resolve({
          name: 'clarity.tasks',
          query: { task: task.id },
        });
        window.open(taskUrl.href, '_blank');
      } else {
        const taskUrl = this.$router.resolve({
          name: this.getTaskRouteName(task.type),
          params: { tenantId: task.businessId, taskId: task.id },
        });
        window.open(taskUrl.href, '_blank');
      }
    },
    isExpandable(id, requestComment) {
      const div = document.querySelector('.stylelessDiv');
      const element = document.createElement('div');
      element.id = `temporary-${id}`;
      element.style.width = '31rem';
      element.style.overflowWrap = 'break-word';
      element.textContent = requestComment;
      div.appendChild(element);
      const temporary = document.getElementById(`temporary-${id}`);
      const clientHeight = temporary.clientHeight;
      div.removeChild(element);
      return clientHeight > 20;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$message('Copied to clipboard');
    },
    formatMsDate(ms) {
      return DateTime.fromMillis(ms).toFormat('HH:mm, dd.MM.yy');
    },
    handleBusinessPopover(popoverId) {
      this.currentOpenBusinessPopover = this.currentOpenBusinessPopover === popoverId ? null : popoverId;
    },
    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.currentOpenBusinessPopover = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .el-select-dropdown.el-popper {
    width: 250px;
  }
  .table-responsive {
    overflow: unset;
  }
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
}

.expandable:hover,
.expandableComment:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: -1rem;
  cursor: pointer;
}

.expandable:hover {
  width: 31rem;
}

.expandableComment:hover {
  width: 20rem;
}

.templateDescription {
  font-weight: 500;
}

.request-details-tooltip {
  width: 21.25rem;
}

.initials-pill {
  height: 1rem;
  width: 1rem;
}

.initials-text {
  font-size: 0.45rem;
  line-height: 1rem;
}
</style>
