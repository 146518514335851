<template>
  <div>
    <el-dialog visible append-to-body :show-close="false" custom-class="rounded close-modal" width="800px">
      <template #title>
        <div class="p-4">
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between">
              <h2 class="title ms-2">{{ $t('requests.serviceProviderResponseModal.requestDetails') }}</h2>
              <RequestTag :request="request" @update-request="$emit('update-request', $event)" />
            </div>
            <Button type="icon" class="p-0 close-button" @click="$emit('close')">
              <CloseIcon :size="20" />
            </Button>
          </div>
          <p>
            {{ request.sourceBusiness.name }}
            <ExchangeIcon class="mx-1" />
            {{ request.targetBusiness.name }}
          </p>
        </div>
      </template>
      <div class="d-flex flex-column gap-5">
        <section v-if="transformedRequest.task" :loading="requesterLoading && responderLoading" class="mt-2">
          <Details :request="transformedRequest" :task="transformedRequest.task" />
        </section>
        <div class="d-flex flex-column gap-5" :class="$t('direction') === 'rtl' ? 'ms-5' : 'me-5'">
          <div class="d-flex flex-column gap-3">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center gap-2">
                <RequestIcon :size="16" />
                <p class="fw-semibold">
                  {{ $t('requests.serviceProviderResponseModal.requestDetails') }}
                </p>
              </div>
              <div class="d-flex align-items-center gap-2 text-typography-secondary">
                <div v-if="transformedRequest.createdBy" class="d-flex align-items-center gap-2">
                  <UserInitialAvatar :size="16" :user="transformedRequest.createdBy" />
                  <small>{{ requestCreatedBy }}</small>
                </div>
                <span v-else>-</span>
                <span>&#183;</span>
                <small>{{ formatMsDate(transformedRequest.createdAt) }}</small>
              </div>
            </div>
            <Expand
              :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
              :max-height="transformedRequest?.responses?.length ? 24 : 105"
            >
              <template #content>
                <p v-for="(line, index) in splittedDetails" :key="index" class="text-break">
                  {{ line }}
                </p>
              </template>
            </Expand>
            <div v-if="transformedRequest.responses?.length" class="d-flex flex-column gap-6 responses">
              <div v-for="(response, responseIndex) in transformedRequest.responses" :key="responseIndex">
                <div class="d-flex flex-column response">
                  <div v-if="!response.isCreatedByAdmin || isAdmin">
                    <div class="p-3">
                      <div class="d-flex mb-2 justify-content-between">
                        <div class="fw-bold lh-1 d-flex gap-2">
                          <ReplyIcon fill="#9295A5" :size="16" />
                          <span>
                            {{
                              $t('requests.responseFrom', {
                                responder: response.isCreatedByAdmin ? 'Clarity' : responder.name,
                              })
                            }}
                          </span>
                        </div>
                        <div class="d-flex align-items-center gap-2 text-typography-secondary">
                          <div
                            v-if="transformedRequest.responses[responseIndex].createdBy"
                            class="d-flex align-items-center gap-2"
                          >
                            <UserInitialAvatar
                              :size="16"
                              :user="transformedRequest.responses[responseIndex].createdBy"
                            />
                            <small>{{ respondCreatedBy(responseIndex) }}</small>
                          </div>
                          <span v-else>-</span>
                          <span>&#183;</span>
                          <small>{{ formatMsDate(transformedRequest.responses[responseIndex]?.createdAt) }}</small>
                        </div>
                      </div>
                      <div v-if="response.attachments" class="d-flex mb-5 mx-5 flex-wrap gap-2">
                        <div
                          v-for="(filename, attachmentIndex) in response.attachments.map((path) =>
                            path.split('/').pop()
                          )"
                          :key="attachmentIndex"
                          class="attachment d-flex align-items-center gap-2"
                          role="button"
                          @click="onFileClick(transformedRequest, responseIndex, attachmentIndex)"
                        >
                          <PDFIcon :size="16" />
                          <span class="mr-2" style="font-size: 12px">{{ filename }}</span>
                        </div>
                      </div>
                      <p v-if="response.text && transformedRequest.responses" class="mx-5">
                        {{ transformedRequest.responses[responseIndex].text }}
                      </p>
                    </div>
                    <div v-if="response.reject?.text" class="d-flex flex-column gap-2 p-3 reject border-top-dashed">
                      <div class="d-flex gap-2 justify-content-between">
                        <div class="d-flex gap-2 align-items-center">
                          <NoticeFullIcon fill="#E52044" :size="16" />
                          <p class="fw-bold">{{ $t('requests.invalidResponseFeedback') }}</p>
                        </div>
                        <div class="d-flex align-items-center gap-2 text-typography-secondary">
                          <div
                            v-if="transformedRequest.responses[responseIndex].reject.createdBy"
                            class="d-flex align-items-center gap-2"
                          >
                            <UserInitialAvatar
                              :size="16"
                              :user="transformedRequest.responses[responseIndex].reject.createdBy"
                            />
                            <small>{{ respondRejectCreatedBy(responseIndex) }}</small>
                          </div>
                          <span v-else>-</span>
                          <span>&#183;</span>
                          <small>{{
                            formatMsDate(transformedRequest?.responses[responseIndex].reject.createdAt)
                          }}</small>
                        </div>
                      </div>
                      <Expand class="text-start text-break mx-5" :max-height="105">
                        <template #content>
                          <div>
                            <p
                              v-for="(line, index) in transformedRequest.responses[responseIndex]?.reject.text.split(
                                '\n'
                              )"
                              :key="index"
                            >
                              {{ line }}
                            </p>
                          </div>
                        </template>
                      </Expand>
                    </div>
                    <div
                      v-else-if="responseIndex + 1 === transformedRequest.responses.length"
                      class="border-top-dashed p-3"
                    >
                      <div v-if="transformedRequest.closed" class="d-flex justify-content-between">
                        <div class="d-flex gap-2 align-items-center">
                          <CheckCircleFullIcon class="text-success" :size="16" />
                          <p class="fw-bold">{{ $t('requests.requestClosed') }}</p>
                        </div>
                        <div class="d-flex align-items-center gap-2 text-typography-secondary">
                          <div v-if="transformedRequest.closedBy" class="d-flex align-items-center gap-2">
                            <UserInitialAvatar :size="16" :user="transformedRequest.closedBy" />
                            <small>{{ requestClosedBy }} </small>
                          </div>
                          <span v-else>-</span>
                          <span>&#183;</span>
                          <small>{{ formatMsDate(transformedRequest.closedAt) }}</small>
                        </div>
                      </div>
                      <div v-else class="d-flex gap-2 align-items-center">
                        <NoticeFullIcon fill="#306FEB" :size="16" />
                        <p class="fw-bold" style="color: #306feb">{{ $t('requests.responseIsBeingChecked') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="d-flex flex-column gap-3">
            <div class="d-flex align-items-center hover-chevron" @click="isRequestNotesShown = !isRequestNotesShown">
              <NoteIcon :size="16" />
              <p class="fw-semibold mx-2">
                {{ $t('requests.serviceProviderResponseModal.comments') }}
              </p>
              <ChevronUpIcon v-if="isRequestNotesShown" :size="16" /><ChevronDownIcon v-else :size="16" />
            </div>
            <div
              v-if="transformedRequest.comment && isRequestNotesShown"
              :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
            >
              <div v-for="(line, index) in splittedComments" :key="index">
                <p class="mb-2 text-break">
                  {{ line }}
                </p>
              </div>
            </div>
          </section>
          <Inquiries
            :inquiries="inquiriesWithRequestId || []"
            :is-inquiries-shown="isInquiriesShown"
            :inquiries-list-custom-class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
            :class="{ 'mb-4': viewOnly }"
          >
            <template #title>
              <div class="d-flex align-items-center hover-chevron" @click="isInquiriesShown = !isInquiriesShown">
                <ClockIcon :size="16" />
                <p class="fw-semibold mx-2">
                  {{ $t('requests.snoozeModal.inquiries') }}
                </p>
                <ChevronUpIcon v-if="isInquiriesShown" :size="16" /><ChevronDownIcon v-else :size="16" />
              </div>
            </template>
            <template v-if="!viewOnly" #inquiry-input>
              <InquiryInput
                v-if="isInquiriesShown"
                :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
                :inquiry.sync="requestInquiryInput"
                @create-inquiry="createInquiryHandler"
              />
            </template>
            <template #custom-content>
              <p
                v-if="isInquiriesShown && viewOnly && !inquiriesWithRequestId.length"
                class="text-typography-secondary"
                :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
              >
                {{ $t('requests.serviceProviderResponseModal.noInquiriesFound') }}
              </p>
            </template>
          </Inquiries>
          <div v-if="!viewOnly" class="text-typography-primary d-flex flex-column gap-3">
            <div class="d-flex align-items-center">
              <ReplyIcon :size="16" />
              <p class="fw-semibold mx-2">{{ $t('requests.serviceProviderResponseModal.response') }}</p>
            </div>
            <el-input
              v-model="answer"
              :class="$t('direction') === 'rtl' ? 'me-5' : 'ms-5'"
              style="width: 96.5%"
              :placeholder="$t('requests.snoozeModal.replyInputPlaceholder')"
              type="text-area"
              :rows="2"
              resize="none"
            />
          </div>
        </div>
      </div>
      <template v-if="!viewOnly" #footer>
        <div class="d-flex justify-content-end">
          <el-tooltip
            :disabled="canSnooze"
            effect="dark"
            :content="$t('requests.serviceProviderResponseModal.snoozeButton.tooltips.cannotSnooze')"
            placement="top"
          >
            <Button type="secondary" :disabled="!canSnooze" @click="$emit('snooze')">
              <SnoozeIcon :class="{ 'snooze-icon-is-disabled': !canSnooze }" />
              {{ $t('requests.serviceProviderResponseModal.buttons.snooze') }}
            </Button>
          </el-tooltip>
          <Button :disabled="!answer?.length" @click="handleCreateRequestResponse">{{
            $t('requests.serviceProviderResponseModal.buttons.sendingResponse')
          }}</Button>
        </div>
      </template>
    </el-dialog>
    <ActionsModal
      v-if="confirmCreateRequestResponseToggle"
      :toggle-dialog="confirmCreateRequestResponseToggle"
      :title="$t('requests.snoozeModal.inquiryNotSavedTitle')"
      :content="$t('requests.snoozeModal.inquiryNotSavedContent')"
      :confirm-button-text="$t('requests.snoozeModal.save')"
      :cancel-button-text="$t('requests.snoozeModal.skip')"
      dialog-type="info"
      @on-cancel="$emit('create-request-response', answer)"
      @on-close="confirmCreateRequestResponseToggle = false"
      @on-confirm="
        () => {
          createInquiryHandler();
          $emit('create-request-response', answer);
        }
      "
    />
    <el-dialog append-to-body :visible.sync="dialogVisible">
      <iframe :src="currentFileUrl" style="width: 100%; height: 50vh"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch, toRefs } from 'vue';
import { isEmpty, isNil } from 'ramda';
import { DateTime } from 'luxon';

import { ActionsModal, Button, Expand } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { useBusinessById } from '@/modules/business/compositions/business';
import { ROLE_TYPE } from '@/modules/users/constants';
import UserInitialAvatar from '@/modules/orderProcess/components/UserAvatar/UserInitialAvatar';
import {
  RequestIcon,
  NoteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ClockIcon,
  ExchangeIcon,
  CloseIcon,
  ReplyIcon,
  PDFIcon,
  NoticeFullIcon,
  SnoozeIcon,
  CheckCircleFullIcon,
} from '@/assets/icons';
import Details from '../components/responseModals/components/Details';
import { useCreateInquiry } from '../compositions/createInquiry';
import InquiryInput from './InquiryInput';
import Inquiries from './Inquiries';
import { USER_QUERY } from '../compositions';
import RequestTag from './RequestTag.vue';
export default {
  components: {
    Button,
    ActionsModal,
    InquiryInput,
    Inquiries,
    ExchangeIcon,
    CloseIcon,
    ReplyIcon,
    RequestIcon,
    PDFIcon,
    NoticeFullIcon,
    RequestTag,
    Details,
    UserInitialAvatar,
    NoteIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ClockIcon,
    Expand,
    SnoozeIcon,
    CheckCircleFullIcon,
  },
  props: {
    request: { type: Object, required: true },
    viewOnly: { type: Boolean, default: false },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { isAdmin } = useUser();

    const { mutate: createInquiry } = useCreateInquiry();
    const { request } = toRefs(props);
    const transformedRequest = ref({ ...props.request });
    watch(
      request,
      async (request) => {
        await Promise.all(
          (request.responses ?? []).map((response) =>
            root.$apollo.query({ query: USER_QUERY, variables: { id: response?.createdBy?.id } }).then(({ data }) => ({
              ...response,
              isCreatedByAdmin: data.user.memberships.some(({ role }) => role === ROLE_TYPE.ADMIN),
            }))
          )
        ).then((data) => (transformedRequest.value = { ...request, responses: data }));
      },
      { immediate: true, deep: true }
    );
    const { business: requester, loading: requesterLoading } = useBusinessById(
      computed(() => ({ businessId: props.request.sourceBusinessId }))
    );

    const { business: responder, loading: responderLoading } = useBusinessById(
      computed(() => ({ businessId: props.request.targetBusinessId }))
    );

    const answer = ref('');

    const canSnooze = computed(() => {
      return answer.value.trim().length === 0;
    });

    return {
      requestInquiryInput: ref(),
      confirmCreateRequestResponseToggle: ref(false),
      isRequestNotesShown: ref(true),
      isInquiriesShown: ref(true),
      createInquiry,
      inquiriesWithRequestId: computed(() =>
        (props.request.inquiries ?? []).map((inquiry) => ({ ...inquiry, requestId: props.request?.id }))
      ),
      transformedRequest,
      currentFileUrl: ref(null),
      dialogVisible: ref(false),
      isNil,
      requester,
      responder,
      requesterLoading,
      responderLoading,
      translate: (key, params) => root.$t(`requestsSupplier.responseModal.${key}`, params),
      isAdmin,
      missingDocs: props.request.templateData?.balanceAlignmentMissingDocuments?.documents ?? [],
      answer,
      canSnooze,
    };
  },
  computed: {
    requestCreatedBy() {
      return (
        this.transformedRequest?.createdBy &&
        `${this.transformedRequest.createdBy.firstName} ${this.transformedRequest.createdBy.lastName}`
      );
    },
    requestClosedBy() {
      return (
        this.transformedRequest.closedBy &&
        `${this.transformedRequest.closedBy.firstName} ${this.transformedRequest.closedBy.lastName}`
      );
    },
    splittedDetails() {
      if (this.transformedRequest?.details) {
        return this.transformedRequest.details.split('\n');
      }
      return [];
    },
    splittedComments() {
      if (this.transformedRequest?.comment) {
        return this.transformedRequest.comment.split('\n');
      }
      return [];
    },
  },
  methods: {
    respondCreatedBy(index) {
      return (
        this.transformedRequest?.responses[index]?.createdBy &&
        `${this.transformedRequest?.responses[index].createdBy.firstName} ${this.transformedRequest.responses[index].createdBy.lastName}`
      );
    },
    respondRejectCreatedBy(index) {
      return (
        this.transformedRequest?.responses[index].reject?.createdBy &&
        `${this.transformedRequest?.responses[index].reject.createdBy.firstName} ${this.transformedRequest?.responses[index].reject.createdBy.lastName}`
      );
    },
    onFileClick(request, responseIndex, attachmentIndex) {
      this.currentFileUrl = request.responses[responseIndex].filePathUrls[attachmentIndex];
      this.dialogVisible = true;
    },
    handleCreateRequestResponse() {
      if (!this.requestInquiryInput || isEmpty(this.requestInquiryInput)) {
        this.$emit('create-request-response', this.answer);
      } else {
        this.confirmCreateRequestResponseToggle = true;
      }
    },
    async createInquiryHandler() {
      await this.createInquiry({
        data: { content: this.requestInquiryInput },
        requestId: this.request.id,
      });
      this.requestInquiryInput = null;
    },
    formatMsDate(ms) {
      return ms ? DateTime.fromMillis(ms).toFormat('HH:mm, dd.MM.yy') : '-';
    },
    showFile(url) {
      this.currentFileUrl = url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
$border-color: #bbbec2;

::v-deep .close-modal {
  .el-dialog__header {
    border-bottom: 1px solid #e3e4e6;
    padding: unset;
    margin: unset;
  }

  .el-dialog__body {
    padding: 1rem;
    color: #131416;
  }

  .el-dialog__footer {
    padding: 1rem;
  }
}

.details {
  word-break: break-word;
}

.close-button {
  color: $typography-secondary;
  &:hover {
    background: #fff !important;
    color: $typography-primary !important;
  }
}

.response {
  background: #f3f3f4;
  margin-inline-start: 1.5rem;
  position: relative;
  border-radius: 4px;
}

.response::before {
  content: '';
  position: absolute;
  top: -5px;
  right: 10px;
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: #f3f3f4;
  border-radius: 2px;
}

.reject {
  background: #f3f3f4;
  padding-top: 8px;
  position: relative;
}

.attachment {
  width: fit-content;
  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #e3e4e6;
  cursor: pointer;
  background: #fff;
}

.rounded-div-container {
  height: 1rem;
  width: 1rem;
  box-shadow: 0 0 0 1px #d9dcde;
  position: relative;
  border-radius: 50%;
  cursor: default;
}

.initials-pill {
  height: 1rem;
  width: 1rem;
}

.initials-text {
  font-size: 0.45rem;
  line-height: 1rem;
}

.profile-pic {
  box-shadow: 0 0 0 1px #d9dcde;
  position: relative;
  border-radius: 50%;
  line-height: 1rem;
}

.hover-chevron {
  cursor: pointer;
}

.custom-textarea .el-textarea__inner {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  resize: none;
  font: inherit;
}

.snooze-icon-is-disabled {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(100%);
  cursor: not-allowed;
  stroke: #dcdfe6;
  fill: #f5f7fa;
}

.border-top-dashed {
  border-top: 1px dashed #d2d4d7;
}
</style>
