<template>
  <div class="d-flex">
    <div
      :class="requestBackgroundClass"
      class="d-flex request flex-grow-1"
      @click.stop="handleRequestClick()"
      @mouseover="setRequestActiveHoverActive()"
      @mouseleave="setRequestActiveHoverInactive()"
    >
      <div class="request-icon-container d-flex align-items-center justify-content-center" :class="iconContainerClass">
        <CheckIcon v-if="request.closed" height="10" class="closed-icon" />
        <div v-else-if="!displayMode && requestActiveHover && request.isAwaitingResponse" class="h-100">
          <EditFillIcon class="request-icon" :fill="requestOverdue ? '#763700' : '#46494F'" />
        </div>
        <div v-else-if="request.isAwaitingResponse" class="h-100">
          <BellUserIcon v-if="requestOverdue && requestSnoozedByUser" class="bell-user-icon" :fill="'#763700'" />
          <BellOutlinesIcon v-else-if="requestOverdue" class="request-icon" :fill="'#763700'" />
          <SnoozeIcon v-else class="request-icon" />
        </div>
        <p v-else class="h-100 align-items-center justify-content-center px-1">
          <HourglassIcon fill="#306FEB" />
        </p>
      </div>
      <div class="request-time-text d-flex align-items-center justify-content-center w-100" :class="textColorClass">
        {{ getRelativeFromNowString() }}
      </div>
    </div>

    <div @click.stop="">
      <RequestSnoozeModal
        v-if="openSnoozeModal"
        :request="request"
        @update-request="handleRequestUpdate"
        @close="openSnoozeModal = false"
      >
        <template #subtile>
          <slot name="request-snooze-modal-subtitle" />
        </template>
      </RequestSnoozeModal>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { isNil } from 'ramda';
import { DateTime } from 'luxon';

import { BellOutlinesIcon, EditFillIcon, SnoozeIcon, BellUserIcon, CheckIcon, HourglassIcon } from '@/assets/icons';

import RequestSnoozeModal from './RequestSnoozeModal';

export default {
  name: 'RequestTag',
  components: {
    BellOutlinesIcon,
    EditFillIcon,
    SnoozeIcon,
    BellUserIcon,
    RequestSnoozeModal,
    CheckIcon,
    HourglassIcon,
  },
  props: {
    request: { type: Object, required: true },
    displayMode: { type: Boolean, required: false },
  },
  setup() {
    return {
      requestActiveHover: ref(false),
      currentTime: ref(new Date()),
      openSnoozeModal: ref(false),
    };
  },
  computed: {
    computedTime: {
      get() {
        return this.currentTime;
      },
      set(newValue) {
        this.currentTime = newValue;
      },
    },

    requestBackgroundClass() {
      if (this.request.closed) {
        return 'closed-request-background';
      }
      if (!this.request.isAwaitingResponse) {
        return 'in-review-request-background';
      }
      if (this.displayMode) {
        return this.requestOverdue
          ? 'active-request-background-displayMode'
          : 'inactive-request-background-displayMode';
      } else {
        return this.requestOverdue ? 'active-request-background' : 'inactive-request-background';
      }
    },
    textColorClass() {
      if (this.request.closed) return 'text-color-closed';
      if (!this.request.isAwaitingResponse) return 'text-color-in-review';
      return this.requestOverdue ? 'text-color-overdue' : 'text-color-snooze';
    },
    requestSnoozedByUser() {
      return !isNil(this.request.activeBy);
    },
    iconContainerClass() {
      if (this.request.closed) return 'request-icon-border-closed';
      if (!this.request.isAwaitingResponse) return 'request-icon-border-in-review';
      const requestIconClass = this.requestOverdue ? 'request-icon-border-overdue' : 'request-icon-border-snooze';
      return this.requestActiveHover ? requestIconClass + '-hover' : requestIconClass;
    },
    requestOverdue() {
      return this.request.activeAt && DateTime.fromMillis(this.request.activeAt) <= DateTime.fromJSDate(new Date());
    },
  },
  mounted() {
    this.updateTimeInterval = setInterval(this.updateComputedTime, 5000);
  },
  beforeDestroy() {
    clearInterval(this.updateTimeInterval);
  },
  methods: {
    handleRequestUpdate(updateData) {
      this.$emit('update-request', updateData);
    },
    handleRequestClick() {
      if (this.request.closed || !this.request.isAwaitingResponse) return;
      if (!this.displayMode) {
        this.openSnoozeModal = true;
      }
    },
    setRequestActiveHoverActive() {
      this.requestActiveHover = true;
    },
    setRequestActiveHoverInactive() {
      this.requestActiveHover = false;
    },
    getRelativeFromNowString() {
      const responses = this.request.responses;
      const relevantTime = this.request.closed
        ? this.request.closedAt
        : this.request.isAwaitingResponse
        ? this.request.activeAt
        : responses && responses.at(-1).createdAt;
      return DateTime.fromMillis(relevantTime).toRelative();
    },
    updateComputedTime() {
      this.computedTime = new Date();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.request {
  width: 98px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  &-icon-container {
    height: 24px;
    width: 24px;
  }
  &-icon {
    width: 24px;
    height: 16px;
  }
  &-time-text {
    font-weight: 500;
    font-size: 10px;
  }

  &.closed-request-background {
    cursor: default;
  }
}

.bell-user-icon {
  width: 24px;
  height: 24px;
}

.inactive-request-background {
  background-color: #f3f3f4;
  &__displayMode {
    background-color: #f3f3f4;
  }
  &:hover {
    background-color: #e3e4e6;
  }
}

.active-request-background {
  background-color: #f9f2e8;
  &__displayMode {
    background-color: #f9f2e8;
  }
  &:hover {
    background-color: #f4e2cd;
  }
}

.closed-request-background {
  background-color: #e9f5ef;
}

.in-review-request-background {
  background-color: #d9e0fd;
}

.request-icon-border-overdue {
  border-left: 1px solid #f4e2cd;
  &-hover {
    border-left: 1px solid #edcfac;
  }
}

.request-icon-border-snooze {
  border-left: 1px solid #e3e4e6;
  &-hover {
    border-left: 1px solid #d2d4d7;
  }
}

.request-icon-border-closed {
  border-left: 1px solid #d3ebe0;
}

.request-icon-border-in-review {
  border-left: 1px solid #c4cfe6;
}

.closed-icon {
  color: #00552a;
}

.text-color-closed {
  color: #00552a;
}

.text-color-in-review {
  color: #306feb;
}

.text-color-overdue {
  color: #763700;
}
.text-color-snooze {
  color: #46494f;
}
</style>
