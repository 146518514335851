import { gql } from '@apollo/client/core';
import { computed, watch, ref, onUnmounted } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export const useRequests = (variables) => {
  const { error } = useNotification();
  const { result, loading: queryLoading, refetch, onError } = useQuery(REQUESTS_QUERY, variables);
  const { mutate, onDone: onUpdateDone, onError: onUpdateError } = useMutation(REQUEST_UPDATE_MUTATION);

  const requestsConnection = useResult(result, { nodes: [], totalCount: 0 });
  const requests = computed(() => requestsConnection.value.nodes);
  const totalCount = computed(() => requestsConnection.value.totalCount);
  const fetching = useLoading(queryLoading, variables);

  const loading = ref(false);

  onError((err) => {
    console.error('useRequests', err);
    error();
  });

  onUpdateError((err) => {
    console.error('useRequests.update', err);
    error();
  });

  onUpdateDone(async () => {
    await refetch();
    loading.value = false;
  });

  watch(
    [fetching],
    ([fetching]) => {
      loading.value = fetching;
    },
    { immediate: true }
  );

  const updateRequest = async (args) => {
    loading.value = true;
    mutate(args);
  };

  return {
    requests,
    totalCount,
    loading,
    refetch,
    onUpdateDone,
    onUpdateError,
    updateRequest,
  };
};

const onDoneSubscribedCallbacks = [];

export const useCreateResponse = () => {
  const { mutate, onDone, onError } = useMutation(REQUEST_RESPONSE_CREATE);

  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });
  const onDoneCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    mutate,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError,
  };
};

export const REQUESTS_QUERY = gql`
  query requests(
    $limit: Int
    $offset: Int
    $sourceBusinessId: String
    $targetBusinessId: String
    $taskId: String
    $closed: Boolean
    $isAwaitingResponse: Boolean
    $sort: Sort
  ) {
    requests(
      limit: $limit
      offset: $offset
      sourceBusinessId: $sourceBusinessId
      targetBusinessId: $targetBusinessId
      taskId: $taskId
      closed: $closed
      isAwaitingResponse: $isAwaitingResponse
      sort: $sort
    ) {
      totalCount
      nodes {
        isAwaitingResponse
        id
        sourceBusinessId
        sourceBusiness {
          id
          name
          number
        }
        targetBusinessId
        targetBusiness {
          id
          name
          number
        }
        taskId
        task {
          id
          type
          businessId
        }
        details
        createdBy {
          id
          firstName
          lastName
          email
          profilePictureUrl
        }
        createdAt
        activeBy {
          id
          firstName
          lastName
          email
        }
        activeAt
        comment
        closed
        closedAt
        closedBy {
          id
          firstName
          lastName
          email
        }
        inquiries {
          id
          content
          timestamp
        }
        answer
        template
        templateData {
          balanceAlignment {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentCheckNewInvoices {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentMissingDocuments {
            documents {
              docNumber
              docType
              docDate
            }
          }
          balanceAlignmentReconciliationStatement {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
        }
        responses {
          attachments
          filePathUrls
          text
          reject {
            text
            createdBy {
              id
              firstName
              lastName
              profilePictureUrl
            }
            createdAt
          }
          createdBy {
            id
            firstName
            lastName
            profilePictureUrl
          }
          createdAt
        }
      }
    }
  }
`;

export const REQUEST_UPDATE_MUTATION = gql`
  mutation requestUpdate($id: ID!, $data: RequestUpdateInput) {
    requestUpdate(id: $id, data: $data) {
      id
    }
  }
`;

const REQUEST_RESPONSE_CREATE = gql`
  mutation requestResponseCreate($requestId: ID!, $createParams: ResponseCreateInput!) {
    requestResponseCreate(requestId: $requestId, createParams: $createParams) {
      id
    }
  }
`;
