export const STALING_TIME_RADIO_STATE = {
  NO_CHANGE: 'noChange',
  ONE_HOUR: 'oneHour',
  TWO_HOURS: 'twoHours',
  TOMORROW_AT_NINE: 'tomorrowAtNine',
  DAY_AFTER_TOMORROW_AT_NINE: 'dayAfterTomorrowAtNine',
  NEXT_WEEK_AT_NINE: 'nextWeekAtNine',
  PERSONALIZED: 'personalized',
};

export const REQUEST_TYPES = {
  BALANCE_ALIGNMENT: 'balanceAlignment',
  BALANCE_ALIGNMENT_MISSING_DOCUMENTS: 'balanceAlignmentMissingDocuments',
  BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT: 'balanceAlignmentReconciliationStatement',
};

export const TASK_TYPE = {
  SUPPLIER_CREATION: 'supplierCreation',
  HANDLE_RECONCILIATION: 'handleReconciliation',
  BALANCE_ALIGNMENT: 'balanceAlignment',
  UNCERTAIN_BILLING: 'uncertainBilling',
  DECLARE: 'declare',
  UPLOAD: 'upload',
  RECORD: 'record',
  ALLOCATION_NUMBER: 'allocation',
};

export const TERM_TYPES = {
  PRICING: 'pricing',
  PROMOTION: 'promotion',
  PAYMENT: 'payment',
  GENERAL: 'general',
};
