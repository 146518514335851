<template>
  <div class="details-container p-4">
    <div class="d-flex gap-3 align-items-center">
      <div class="fields d-flex flex-column gap-2 text-typography-secondary">
        <p>{{ translate('requestType') }}</p>
        <p>{{ translate('relatedTask') }}</p>
      </div>
      <div class="d-flex flex-column gap-2">
        <p class="d-flex align-items-center gap-1">
          {{ requestTypeDescription }}
          <el-tooltip placement="top" :content="$t('requests.serviceProviderResponseModal.tooltip.customerVisible')">
            <ShowIcon v-if="isVisibleToSupplier" />
          </el-tooltip>
        </p>
        <p
          v-if="task.type"
          type="link"
          class="related-task fw-normal p-0 text-typography-primary text-decoration-underline"
          @click.stop="openTask(task)"
        >
          {{ $t(`requests.table.taskNames.${task.type}`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, computed } from 'vue';
import { ShowIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { useTerms } from '@/modules/term';

import { TASK_TYPE, REQUEST_TYPES, TERM_TYPES } from '../../../../constants';
import { useBusinessRelation } from '../../../compositions/useBusinessRelation';

export default {
  components: { ShowIcon },
  props: {
    request: { type: Object, required: true },
    task: { type: Object, required: true },
  },
  setup(props) {
    const { $i18n } = getCurrentInstance().proxy;
    const translate = (key, params) => $i18n.t(`requests.serviceProviderResponseModal.${key}`, params);
    const { token } = useTenancy();

    const { terms: generalTerms } = useTerms(
      computed(() => ({
        type: TERM_TYPES.GENERAL,
        businessId: props.request.sourceBusinessId,
        supplierId: props.request.targetBusinessId,
      }))
    );

    const reconciliationDate = computed(() => {
      const data = props.request.templateData;
      if (data && props.request.template === REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS) {
        return data[props.request.template].documents[0].docDate;
      }
      return data?.[props.request.template]?.reconciliationPeriodEnd || null;
    });

    const formattedReconciliationDate = computed(() => {
      if (!reconciliationDate.value) return null;

      const options =
        props.request.template === REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS
          ? { month: 'long', year: 'numeric' }
          : { day: '2-digit', month: '2-digit', year: 'numeric' };

      return new Date(reconciliationDate.value).toLocaleDateString($i18n.locale, options);
    });

    const requestTypeDescription = computed(() => {
      if (props.request.template && props.request.template !== 'other') {
        return `${$i18n.t(`requests.requestsButton.popover.templates.${props.request.template}.title`)} ${translate(
          'forDate',
          { date: formattedReconciliationDate.value }
        )}`;
      }
      return props.request.details.split(' ').slice(0, 6).join(' ');
    });

    const { businessesClarityWorksWith } = useBusinessRelation();

    const isVisibleToSupplier = computed(
      () =>
        Object.values(REQUEST_TYPES).includes(props.request.template) &&
        businessesClarityWorksWith.value.includes(props.request.targetBusinessId)
    );

    return {
      translate,
      token,
      requestTypeDescription,
      formattedReconciliationDate,
      customerNumber: computed(() => generalTerms.value.customerNumber),
      isVisibleToSupplier,
    };
  },
  methods: {
    getTaskRouteName(taskType) {
      switch (taskType) {
        case TASK_TYPE.SUPPLIER_CREATION:
          return 'supplierTasks';
        case TASK_TYPE.HANDLE_RECONCILIATION:
          return 'reconciliationTask';
        case TASK_TYPE.UNCERTAIN_BILLING:
          return 'uncertainBillingTask';
        case TASK_TYPE.UPLOAD:
          return 'classifyDocuments';
        case TASK_TYPE.RECORD:
          return 'recordDocuments';
        default:
          return '';
      }
    },
    openTask(task) {
      if (
        [
          TASK_TYPE.DECLARE,
          TASK_TYPE.BALANCE_ALIGNMENT,
          TASK_TYPE.HANDLE_RECONCILIATION,
          TASK_TYPE.UNCERTAIN_BILLING,
          TASK_TYPE.ALLOCATION_NUMBER,
        ].includes(task.type)
      ) {
        const taskUrl = this.$router.resolve({
          name: 'clarity.tasks',
          query: { task: task.id },
        });
        window.open(taskUrl.href, '_blank');
      } else {
        const taskUrl = this.$router.resolve({
          name: this.getTaskRouteName(task.type),
          params: { tenantId: task.businessId, taskId: task.id },
        });
        window.open(taskUrl.href, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  border: 1px solid #e3e4e6;
  border-radius: 0.25rem;
  width: 768px;

  p {
    line-height: 1.5rem;
  }
}

.related-task {
  cursor: pointer;
}
.fields {
  width: 7.25rem;
}
</style>
